import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";

import TermsAndConditions from "./components/TermsAndConditions";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Hero from "./components/Hero";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Suspense>
        <Hero/>
      </Suspense>}  />
      <Route path="/terms-of-service" element={<Suspense>
        <TermsAndConditions/>
      </Suspense>} />
      <Route path="/privacy-policy" element={<Suspense>
        <PrivacyPolicy/>
      </Suspense>} />
    </Routes>
  );
}

export default App;
