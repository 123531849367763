import axios from "../axios";

export const onboardAgency = async (agencyInfo) => {
  try {
    const { data } = await axios.post("/onboard/submit", agencyInfo);
    return data;
  } catch (error) {
    console.log(error.response.data);
    return error.response.data;
  }
};

