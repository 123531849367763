import React from "react";
import { useEffect, useState } from "react";
import "../App.css";
import BgImage from "../../src/Assets/images/groupygo-bg.jpg";
import logo from "../../src/Assets/images/groupygo-logo.png";
import darkLogo from "../../src/Assets/images/groupygo-logo-dark.png";
import ImportantAstrict from "../common/ImportantAstrict";
import InputErrorMessage from "../common/InputErrorMessage";
import { onboardAgency } from "../api/Agency/agency";
import { toastAlert } from "../utils/alert";

const Hero = () => {
  const [term1, setTerm1] = useState(false);

  const [agencyName, setagencyName] = useState("");
  const [agencylocation, setagencyLocation] = useState("");

  const [messageNumber1, setmessageNumber1] = useState("");
  const [messageNumber2, setmessageNumber2] = useState("");

  const [error, setError] = useState({
    agencyName: false,
    agencylocation: false,
    messageNumber: false,
    terms: false,
  });

  const handleTerm1Update = () => {
    setTerm1((prev) => !prev);
  };

  useEffect(() => {
    setError({
      agencyName: false,
      agencylocation: false,
      messageNumber: false,
      terms: false,
    });
  }, [agencyName, agencylocation, messageNumber1, messageNumber2, term1]);

  const handleFormSubmit = async () => {
    let hasError = false;

    if (!agencyName) {
      setError((prev) => ({ ...prev, agencyName: true }));
      hasError = true;
    }

    if (!agencylocation) {
      setError((prev) => ({ ...prev, agencylocation: true }));
      hasError = true;
    }

    if (!messageNumber1 && !messageNumber2) {
      setError((prev) => ({ ...prev, messageNumber: true }));
      hasError = true;
    }

    if (!term1) {
      setError((prev) => ({ ...prev, terms: true }));
      hasError = true;
    }

    if (!hasError) {
      const agencyData = {
        name: agencyName,
        location: agencylocation,
        messageAuthNumber: [messageNumber1, messageNumber2],
      };

      console.log("This  is submission ::", agencyData);
      const res = await onboardAgency(agencyData);
      console.log("Looggg : ", res);
      toastAlert("Application submitted Successfully.");

      setTerm1(false);
      setagencyName("");
      setagencyLocation("");
      setmessageNumber1("");
      setmessageNumber2("");
    }
  };
  return (
    <div className="App">
      <section className="bg-white">
        <div className="lg:grid lg:min-h-screen lg:grid-cols-12">
          <section className="relative flex h-32 items-end bg-gray-900 lg:col-span-5 lg:h-full xl:col-span-6">
            <img
              alt="Night"
              src={BgImage}
              className="absolute inset-0 h-full w-full object-cover opacity-80"
            />

            <div className="hidden lg:relative lg:block lg:p-12">
              <a className="block text-white" href="/">
                <span className="sr-only">Home</span>
                <img src={logo} alt="Logo" width={"200px"} />
              </a>

              <h2 className="mt-6 text-2xl font-bold text-white sm:text-3xl md:text-4xl">
                Tickets made easy! 🎫 🛫
              </h2>

              <p className="mt-4 leading-relaxed text-white/90">
                Discover the ideal group tickets effortlessly with GroupyGo! Our
                efficient WhatsApp bot streamlines the process for travel
                agencies, providing the best ticket options in seconds. <br />{" "}
                <br /> Say good-bye to time consuming searches in WhatsApp
                groups. Enhance your efficiency by submitting your application
                now. Let GroupyGo simplify your flight searches, making it quick
                and seamless. <br /> <br /> Don't miss the speed and convenience
                – apply now to transform the way you find flight options!
              </p>
            </div>
          </section>

          <main className="flex items-center justify-center px-8 py-8 sm:px-12 lg:col-span-7 lg:px-16 lg:py-12 xl:col-span-6">
            <div className="max-w-xl lg:max-w-3xl">
              <div className="relative block lg:hidden">
                <img src={darkLogo} alt="Logo" width={"100px"} />
                <h1 className="mt-2 text-2xl font-bold text-gray-900 sm:text-3xl md:text-4xl">
                  Tickets made easy! 🎫 🛫
                </h1>

                <p className="mt-4 leading-relaxed text-gray-500">
                  Discover the ideal group tickets effortlessly with GroupyGo!
                  Our efficient WhatsApp bot streamlines the process for travel
                  agencies, providing the best ticket options in seconds. <br />{" "}
                  <br /> Say good-bye to time consuming searches in WhatsApp
                  groups. Enhance your efficiency by submitting your application
                  now. Let GroupyGo simplify your flight searches, making it
                  quick and seamless. <br /> <br /> Don't miss the speed and
                  convenience – apply now to transform the way you find flight
                  options!
                </p>
              </div>

              <form action="#" className="mt-2 grid grid-cols-6 gap-8">
                <div className="col-span-6">
                  <h1 className="text-xl font-semibold text-grey-700">
                    Onboarding Application.
                  </h1>
                  <br />
                  <hr />
                </div>
                <div className="col-span-6">
                  <label
                    htmlFor="Name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {" "}
                    Agency Name <ImportantAstrict />{" "}
                    {error.agencyName && (
                      <InputErrorMessage text={"This field is required"} />
                    )}
                  </label>

                  <input
                    type="text"
                    id="name"
                    name="name"
                    className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm capitalize"
                    value={agencyName}
                    onChange={(e) => setagencyName(e.target.value)}
                  />
                </div>
                <div className="col-span-6">
                  <label
                    htmlFor="Location"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {" "}
                    Agency Location <ImportantAstrict />{" "}
                    {error.agencylocation && (
                      <InputErrorMessage text={"This field is required"} />
                    )}
                  </label>

                  <input
                    type="text"
                    id="location"
                    name="location"
                    className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm capitalize"
                    value={agencylocation}
                    onChange={(e) => setagencyLocation(e.target.value)}
                  />
                </div>

                <div className="col-span-6 sm:col-span-6 grid gap-2">
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="messageNumber1"
                        className={`block text-sm font-medium text-gray-700`}
                      >
                        {" "}
                        Message Number (1)
                        <ImportantAstrict />{" "}
                        {error.messageNumber && (
                          <>
                            <br />
                            <InputErrorMessage
                              text={"This field is required"}
                            />
                          </>
                        )}
                      </label>
                      <input
                        type="tel"
                        id="messageNumber1"
                        name="messageNumber1"
                        className={`mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm capitalize`}
                        value={messageNumber1}
                        onChange={(e) => setmessageNumber1(e.target.value)}
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="messageNumber1"
                        className={`block text-sm font-medium text-gray-700`}
                      >
                        {" "}
                        Message Number (2)
                        <ImportantAstrict />{" "}
                        {error.messageNumber && (
                          <>
                            <br />
                            <InputErrorMessage
                              text={"This field is required"}
                            />
                          </>
                        )}
                      </label>
                      <input
                        type="tel"
                        id="messageNumber2"
                        name="messageNumber2"
                        className={`mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm capitalize`}
                        value={messageNumber2}
                        onChange={(e) => setmessageNumber2(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-span-6">
                  {error.terms && (
                    <>
                      <InputErrorMessage text={"Acknowledge the terms"} />
                    </>
                  )}
                  <label htmlFor="MarketingAccept" className="flex gap-4">
                    <input
                      type="checkbox"
                      id="MarketingAccept"
                      name="term1"
                      className="h-5 w-5 rounded-md border-gray-200 bg-white shadow-sm cursor-pointer"
                      onChange={(e) => handleTerm1Update(e.target.value)}
                      checked={term1}
                    />

                    <span className="text-sm text-gray-700">
                      Payment and other booking activities will occur directly
                      between the agency and the supplier. <br /> GroupyGo will
                      not assume responsibility for these transactions.
                    </span>
                  </label>
                </div>
                <div className="col-span-6 sm:flex sm:items-center sm:gap-4">
                  <span
                    type="submit"
                    className="inline-block cursor-pointer shrink-0 rounded-md border border-blue-600 bg-blue-600 px-12 py-3 text-sm font-medium bg-transparent text-blue-600 transition hover:bg-blue-600 hover:text-white focus:outline-none focus:ring active:text-blue-500"
                    onClick={handleFormSubmit}
                  >
                    Submit Application
                  </span>
                </div>
              </form>
            </div>
          </main>
        </div>
      </section>
    </div>
  );
};

export default Hero;
